import React, { PureComponent } from 'react';
import ServiceProvider from './service/ServiceProvider';
import logo from './../images/logo.png'

class Menu extends PureComponent {

    constructor(props) {
        super(props);
        this.state = { categories: [], isLoaded:false };
    }

    mapResults(data) {
        const result = data.map((category, key) => {
            const {id, controller, controllerAction, displayName, orderNumber} = category;
            return (
                <div id={id} className="menuitem" key={orderNumber}>
                    <a href={'/' + controller + '/' + controllerAction}>{displayName}</a>
                </div>)
        });
        return result;
    }

    serviceCallback = (data) => {
        data.sort((a,b) => a.orderNumber - b.orderNumber).reverse();
        const categoryList = this.mapResults(data);
        this.setState({ categories: categoryList });
        this.setState({ isLoaded:true})
        console.log("state: ", this.state.categories)
    }

    render() {
        
        if(this.state.isLoaded === false)
        {
            return(
            <div>
                <a href="/">
                    <img className="logo" src={logo} alt="Sion-ICT" />
                </a>
                <ServiceProvider url={this.props.url} callbackFn={this.serviceCallback.bind(this)} />
            </div>);
        }
        return (
            <div className="stpanel" role="navigation">
                <a href="/">
                    <img className="logo" src={logo} alt="Sion-ICT" />
                </a>
              <div>
                    {this.state.categories}
              </div>
            </div>
        );
    }
}

export default Menu;