import React, { PureComponent } from 'react';

import LinkedIn from './../images/LinkedIn.png'
import Twitter from './../images/twitter.png'
import WordPress from './../images/wordpress-logo-32-blue.png'

class Footer extends PureComponent {
    render(){
        const rVersion: string=React.version;
        return(
          <React.Fragment>
            <div className="col-lg-2"></div>
            <div className="col-lg-8">
                <div className="footerSide leftColumnAlign col-md-3 col-xs-12">
                    <span className="footerText">&copy;&nbsp;2011-2023 Sion-ICT</span>
                    {/* <br />
                    <br />
                    <span className="footerLink">
                        <a href="/Legal/Voorwaarden">Algemene voorwaarden</a>
                    </span> */}
                    {/* <br />
                    <br />
                    <span className="footerText">ReactJS version: {rVersion}</span> */}
                    <br />
                    <br />
                    <span className="footerLink">
                        <a href="/Legal/PrivacyAndCookiePolicy">Privacy- en cookiebeleid</a>
                    </span>
                    <br />
                    <br />
                </div>
    
                <div className="footerCenter col-md-6 col-xs-12">
                    <span className="footerText">Sion-ICT is geregistreerd bij de Kamer van Koophandel onder nummer 54029899</span>
                    <br />
                    <br />
                </div>
    
                <div className="footerSide col-md-3 col-xs-12">
                    <span className="footerLink">
                        <a href="http://nl.linkedin.com/pub/marcel-c-r-van-driel/8/b5a/452" target="_blank" rel="noopener noreferrer">
                            <img className="socialImage" src={LinkedIn} alt="Marcel C.R. van Driel's LinkedIn profiel"/>
                            Zie Marcel's LinkedIn profiel
                        </a>
                    </span>
                    <br />
                    <br />
                    <span className="footerLink">
                        <a href="https://twitter.com/Sion_ICT" target="_blank" rel="noopener noreferrer">
                            <img className="socialImage" src={Twitter} alt="Sion-ICT op Twitter"/>
                            Zie Sion-ICT op Twitter
                        </a>
                    </span>
                    <br />
                    <br />
                    <span className="footerLink">
                        <a href="http://sionict.wordpress.com/" target="_blank" rel="noopener noreferrer">
                            <img className="socialImage socialAdjust" src={WordPress} alt="Sion-ICT blog"/>
                            Zie Sion-ICT's blog
                        </a>
                    </span>
                    <br />
                    <br />
                </div>
            </div>
            <div className="col-lg-2"></div>
        </React.Fragment>
        );
    }
}

export default Footer;