import React, { PureComponent } from 'react';
import './../css/sionict.css';
import ServiceProvider from './service/ServiceProvider';
import logoSmall from './../images/logo.png'

class MenuMobile extends PureComponent {

    constructor(props) {
        super(props);
        this.state = { categories: [] };
    }

    mapResults(data) {
        const result = data.map((category, key) => {
            const {id, controller, controllerAction, displayName} = category;
            return (
                <li key={id}>
                    <a href={'/' + controller + '/' + controllerAction}>{displayName}</a>
                </li>)
        });
        return result;
    }

    serviceCallback = (data) => {
        data.sort((a,b) => a.orderNumber - b.orderNumber);
        const categoryList = this.mapResults(data);
        this.setState({ categories: categoryList });
        // console.log("state: ", this.state.categories)
    }

    render() {
        return (
            <nav className="navbar navbar-default" role="navigation">
                <ServiceProvider url={this.props.url} callbackFn={this.serviceCallback.bind(this)} />
                <div className="navbar-header">
                    <a className="navbar-brand" href="/"><img className="logoSmall" src={logoSmall} alt="Sion-ICT" /></a>
                    <button type="button" className="navbar-toggle"
                            data-toggle="collapse" data-target="#example-navbar-collapse">
                        <span className="sr-only">Toggle navigation</span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                    </button>
                </div>
                
                <div className="collapse navbar-collapse" id="example-navbar-collapse">
                  <ul className="nav navbar-nav navcolor">
                    {this.state.categories}
                  </ul>
                </div>
            </nav>
        );
    }
}

export default MenuMobile;