import React, { PureComponent } from "react";
import "./../css/sionict.css";
import Menu from "./Menu.jsx";
import MenuMobile from "./MenuMobile.jsx";
import Content from "./Content.jsx";
import Certifications from "./Certifications.tsx";
import mainimage from "./../images/mainimage.png";

class App extends PureComponent {
  render() {
    const { serviceUrl, mapping } = require("./serviceSettings.json");
    return (
      <React.Fragment>
        {/* <div key="1" className="col-lg-2"></div> */}
        <div key="2" className="col-lg-12 col-xs-12">
          <div className="headContainer hidden-xs">
            <div className="menu col-sm-12 hidden-xs">
              <Menu url={serviceUrl} />
            </div>
          </div>
          <div className="headContainer hidden-sm hidden-md hidden-lg">
            <MenuMobile url={serviceUrl} />
          </div>
          <div className="mainImageContainer">
              <img className="mainImage" src={mainimage} alt="Skills"/>
          </div>
          <div className="contentContainer col-xs-12 col-sm-12">
            <Content url={serviceUrl} mapping={mapping} />
          </div>
          <div className="hidden-xs col-sm-12">
            <Certifications />
          </div>
          <div className="col-lg-2"></div>
        </div>
      </React.Fragment>
    );
  }
}

export default App;
