import React, { PureComponent } from 'react';

import MCTS from './../images/MCTS.png'
import MCP from './../images/MCP.png'
import sitecoreLogo from './../images/sitecore_logo.png'
import ADAM from './../images/logo-ADAM-Software.png'

class Certifications extends PureComponent {
    render() {
        // return(
        //     <React.Fragment>
        //         <div className="stpanel col-xs-6 col-sm-3 smallText">
        //             <div className="certification">
        //                 <img className="certificationImage img-responsive" src={MCTS} alt="MCTS .NET 4.0 Data Access" />                        .NET Framework 4, data Access
        //             </div>
        //         </div>

        //         <div className="stpanel col-xs-6 col-sm-3 smallText">
        //             <div className="certification">
        //                 <img className="certificationImage img-responsive" src={MCP} alt="MCP 2.0" />
        //                 MCP 2.0 -- Certified Professional
        //             </div>
        //         </div>

        //         <div className="stpanel col-xs-6 col-sm-3 smallText">
        //             <div className="certification">
        //                 <img className="certificationImage img-responsive" src={sitecoreLogo} alt="Sitecore 6 .NET Developer" />
        //                 Certified Sitecore 6 .NET Developer
        //             </div>
        //         </div>

        //         <div className="stpanel col-xs-6 col-sm-3 smallText">
        //             <div className="certification">
        //                 <img className="certificationImage img-responsive" src={ADAM} alt="ADAM Developer" />
        //                 Certified ADAM Developer
        //             </div>
        //         </div>
        //     </React.Fragment>
        // );
        return (null);
    }
}

export default Certifications;