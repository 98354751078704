import { PureComponent } from 'react';

class Header extends PureComponent {

  render() {
    return (null);
  }
}

export default Header;
