import { PureComponent } from 'react';

interface IProviderData {
    url:string;
    callbackFn({}): any;
}

class ServiceProvider extends PureComponent<IProviderData,{}> {

    sleep(ms:number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    getData(){
        var {url, callbackFn} = this.props;
        // For testing of loading state, set sleep to 1 or 2 seconds
        this.sleep(0).then (() => {
            fetch(url, {
                method: 'GET'
            })
            .then(results => {
                return results.json();
            }) 
            .then(data => {
                callbackFn(data);
            })
        });
    }

    componentDidMount() {
        this.getData();
    }

    render() {
        return (null);
    }
}

export default ServiceProvider;