import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './js/App.jsx';
import Header from './js/Header.tsx';
import Footer from './js/Footer.tsx';
import * as serviceWorker from './serviceWorker';


ReactDOM.render(<Header />, document.getElementById('head'));
ReactDOM.render(<App />, document.getElementById('sitebody'));
ReactDOM.render(<Footer />, document.getElementById('footer'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
