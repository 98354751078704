
export const RouteFromHref = () => {
    const urlwithoutParams = window.location.href.split('?')[0];
    let route = urlwithoutParams.split('/').pop();
    if (route === undefined) {
        route = 'Index'
    };
    return route;
}

export const ServiceRoute = (mapping, url, route) => {
    let serviceRoute = 'home'
    const map = mapping.find(m => m[route] !== undefined);
     if (map !== undefined) {
        serviceRoute = map[route];
    }
    return (url + '/' + serviceRoute);
}
